<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Create Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="supplier == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Warehouse</label>
        <multiselect
          class="selectExample"
          v-model="warehouse"
          :options="optionWarehouse"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="warehouse == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Purchase Order</label>
        <multiselect
          class="selectExample"
          v-model="po"
          :options="optionPO"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="label"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.sources == 'Import' ? dt.option.label : dt.option.code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.sources == 'Import' ? dt.option.label : dt.option.code }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="po == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Good Receive</label>
        <multiselect
          class="selectExample"
          v-model="gr"
          :options="optionGR"
          :multiple="true"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="label"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.label }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.label }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="gr == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
        />
        <span
          class="text-danger text-sm"
          v-if="table.inv_code == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Invoice Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="table.inv_date"
          placeholder="Select Invoice Date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="table.inv_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <!-- <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Created Date"
          name="Created Date"
          v-model="createdDate"
          readonly
        />
      </div>
    </div> -->
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          class="selectExample"
          v-model="table.payment_term"
          :options="optionPaymentTerm"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.payment_term == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <!-- <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          v-model="due_date"
          readonly
        />
      </div> -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Due Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="due_date"
          placeholder="Due date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="due_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="table.bank"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="account_number"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }} - {{ dt.option.bank_branch }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }} - {{ dt.option.bank_branch }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Tax Number"
          name="Tax Number"
          v-model="tr_number"
          @keypress="isNumberAndFormat($event)"
          placeholder="Tax Number"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Date</label>
        <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Date"
          format="MMMM dd, yyyy"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="currency_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty Inv</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <vx-input-group class="">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'qty')"
                class="w-full"
                name="Qty Inv"
                @focus="$event.target.select()"
                v-model="data[indextr].quantity"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].quantity == null && status_submit"
                >This field is required</span
              >

              <template slot="append">
                <div class="append-text bg-dark">
                  <span style="color: black; outline-color: black">{{ data[indextr].item_unit }}</span>
                </div>
              </template>
            </vx-input-group>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'price')"
                @focus="$event.target.select()"
                class="w-full"
                name="Price Inv"
                v-model="data[indextr].net"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].net == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'discount')"
                @focus="$event.target.select()"
                class="w-full"
                name="Discount"
                v-model="data[indextr].discount"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].discount == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].subtotal"
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].tax == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'value')"
                @focus="$event.target.select()"
                class="w-full"
                name="Value"
                v-model="data[indextr].value"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].value == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <div style="display: none">
            <!-- <p>{{ (tax_charges[indextr] = data[indextr].tax) }}</p> -->
            <!-- <p>{{ (charges[indextr] = data[indextr].value) }}</p> -->
          </div>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value"
        name="Item Value"
        v-model="table.item_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value"
        name="Charges Value"
        v-model="table.charges_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value"
        name="Tax Value"
        v-model="table.tax_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value"
        name="Discount Value"
        v-model="table.discount_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value"
        name="Total Invoice Value"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
    <vs-textarea
      v-model="new_notes"
    />
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png, .pdf"
            multiple
          />
        </div>
      </div>
    </div>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button class="mr-3 mb-2" @click="handleCreate">Create</vs-button>
        <!-- <vs-button v-else class="mr-3 mb-2" @click="handleUpdate"
          >Update</vs-button
        > -->
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank: {},
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          discount_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        // tax_item: [],
        // tax_charges: [],
        new_notes: "",
        gr_codes: "",
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
        file: {},
        total_discount: 0,
        type_submit: "",
        tr_number: null,
        tr_date: null,
        due_date: null,
        invoice_baseline: null,
        received_date: null,
        dn_date: null,
        supplier: null,
        warehouse: null,
        po: null,
        gr: null,
        optionSupplier: [],
        optionWarehouse: [],
        optionPaymentTerm: [],
        optionSupplierAccountName: [],
        optionPO: [],
        optionGR: [],
        grOrderNumber: "",
        gr_ids: "",
        currency_code: "",
        po_code_external: "",
        attachment_ids: []
      };
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode != 8 && this.tr_number != null) {
        if (this.tr_number.length == 3) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length == 7) {
          this.tr_number = this.tr_number + "-";
        } else if (this.tr_number.length == 10) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length > 18) {
          evt.preventDefault();
        }
      }
    },
    getOptionAccountBank(supplier_id, bankIDSelected = 0) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account/" + supplier_id, {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          let option = [];
          console.log("account")
          console.log(resp.data.account)
          resp.data.account.map((v) => {
            const newOption = {
              bank_name: v.BankName,
              bank_id: v.BankID,
              account_name: v.AccountName,
              account_number: v.AccountNumber,
              bank_branch: "Branch " + v.BankBranch,
            };
            this.optionSupplierAccountName.push(newOption);
            if (bankIDSelected != 0 && bankIDSelected != "") {
              if (v.BankID == bankIDSelected) {
                // this.table.bank = {
                //   bank_name : v.BankName,
                //   bank_id: v.BankID,
                //   account_name: v.AccountName,
                //   account_number: v.AccountNumber
                // }
              }
            } else {
              if (v.IsDefault == 1) {
                this.table.bank = {
                  bank_name: v.BankName,
                  bank_id: v.BankID,
                  account_name: v.AccountName,
                  account_number: v.AccountNumber,
                  bank_branch: "Branch " + v.BankBranch,
                };
              }
            }
          });
          this.$vs.loading.close();
        });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPaymentTerm = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
    },
    handleAttachment() {
      if (this.$refs.file.files[0]) {
        let a = this.$refs.file.files
        for (let i = 0; i < a.length; i++) {
          console.log(a[i])
          if(a[i].size <= 5000000){ // 5mb
            this.file = a[i];
            this.uploadData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: a[i].name + " is greater than 5MB",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "No file chosen.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        window.open(selected.path);
      }
    },
    // handleUpdate() {
    //   this.status_submit = true;
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.type_submit = "update";
    //       this.$vs.loading();
    //       this.postData();
    //       console.log(this.paramData());
    //     }
    //   });
    // },
    handleCreate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "create";
          this.$vs.loading();
          this.postData();
          console.log(this.paramData());
        }
      });
    },
    formatNumber(index, status) {
      // console.log(status)
      if (status === "qty") {
        if (this.table.data[index].quantity.replace(/,/g, "").length < 16) {
          var str = this.table.data[index].quantity.toString().split(".");
          if (str.length > 2) {
            this.table.data[index].quantity = this.table.data[index].quantity
              .toString()
              .replace(/\.+$/, "");
          }

          if (str.length == 2) {
            if (str[1].length > 3) {
              this.table.data[index].quantity = this.table.data[index].quantity
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].quantity = this.table.data[index].quantity
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.data[index].quantity = this.table.data[index].quantity
            .toString()
            .slice(0, -1);
        }
      } else if (status === "price") {
        if (this.table.data[index].net.replace(/,/g, "").length < 16) {
          var str1 = this.table.data[index].net.toString().split(".");
          if (str1.length > 2) {
            this.table.data[index].net = this.table.data[index].net
              .toString()
              .replace(/\.+$/, "");
          }

          if (str1.length == 2) {
            if (str1[1].length > 3) {
              this.table.data[index].net = this.table.data[index].net
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].net = this.table.data[index].net
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.net[index] = this.table.data[index].net;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      } else if (status === "value") {
        if (
          this.table.data_charges[index].value.replace(/,/g, "").length < 16
        ) {
          var str2 = this.table.data_charges[index].value.toString().split(".");
          if (str2.length > 2) {
            this.table.data_charges[index].value = this.table.data_charges[
              index
            ].value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str2.length == 2) {
            if (str2[1].length > 3) {
              this.table.data_charges[index].value = this.table.data_charges[
                index
              ].value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.charges[index] = this.table.data_charges[index].value;

          this.cal();
        } else {
          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax") {
        // if (this.table.data_charges[index].tax.replace(/,/g, "").length < 16) {
        //   var str3 = this.table.data_charges[index].tax.toString().split(".");
        //   if (str3.length > 2) {
        //     this.table.data_charges[index].tax = this.table.data_charges[
        //       index
        //     ].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str3.length == 2) {
        //     if (str3[1].length > 3) {
        //       this.table.data_charges[index].tax = this.table.data_charges[
        //         index
        //       ].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data_charges[index].tax = Number(
        //     this.table.data_charges[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_charges[index] = this.table.data_charges[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data_charges[index].tax = this.table.data_charges[
        //     index
        //   ].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "tax-item") {
        // if (this.table.data[index].tax.replace(/,/g, "").length < 16) {
        //   var str4 = this.table.data[index].tax.toString().split(".");
        //   if (str4.length > 2) {
        //     this.table.data[index].tax = this.table.data[index].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str4.length == 2) {
        //     if (str4[1].length > 3) {
        //       this.table.data[index].tax = this.table.data[index].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data[index].tax = Number(
        //     this.table.data[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_item[index] = this.table.data[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data[index].tax = this.table.data[index].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "discount") {
        if (this.table.data[index].discount.replace(/,/g, "").length < 16) {
          var str5 = this.table.data[index].discount.toString().split(".");
          if (str5.length > 2) {
            this.table.data[index].discount = this.table.data[index].discount
              .toString()
              .replace(/\.+$/, "");
          }

          if (str5.length == 2) {
            if (str5[1].length > 3) {
              this.table.data[index].discount = this.table.data[index].discount
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].discount = this.table.data[index].discount
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.discount[index] = this.table.data[index].discount;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      }
    },
    cal() {
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;

      // calculate tax item
      this.table.data.map((r, i) => {
        this.table.data[i].tax =
          parseFloat(parseInt(this.table.data[i].tax_rate) / 100) *
          parseFloat(
            this.table.data[i].net.replace(/,/g, "") -
              parseFloat(
                this.table.data[i].discount.toString().replace(/,/g, "")
              )
          );
        this.table.data[i].subtotal = parseFloat(
          this.table.data[i].net.replace(/,/g, "") -
            parseFloat(this.table.data[i].discount.toString().replace(/,/g, ""))
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        a += this.table.data[i].tax;
        e += parseFloat(
          this.table.data[i].discount.toString().replace(/,/g, "")
        );
        d += parseFloat(this.table.data[i].net.toString().replace(/,/g, ""));
        this.table.data[i].tax = this.table.data[i].tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // calculate tax charge
      this.table.data_charges.map((r, i) => {
        this.table.data_charges[i].tax =
          (this.table.data_charges[i].tax_rate / 100) *
          parseInt(this.table.data_charges[i].value.replace(/,/g, ""));
        b += this.table.data_charges[i].tax;
        c += parseFloat(
          this.table.data_charges[i].value.toString().replace(/,/g, "")
        );
        this.table.data_charges[i].tax = this.table.data_charges[i].tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // let a = 0;
      // this.tax_item.forEach((element) => {
      //   if (element !== "") {
      //     a += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // let b = 0;
      // this.tax_charges.forEach((element) => {
      //   if (element !== "") {
      //     b += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });
      // this.charges.forEach((element) => {
      //   if (element !== "") {
      //     c += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // this.net.forEach((element) => {
      //   if (element !== "") {
      //     d += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      this.$nextTick(() => {
        this.table.tax_value = parseInt(a + b) // tax invoice & tax charge
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.tax_value_ori = (a + b)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.charges_value = c
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.item_value = d
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.discount_value = e
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.total_inv_value = (parseInt(a) + parseInt(b) + c + d - e)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        supplier_id: this.supplier.ID,
        warehouse_name: this.warehouse.name,
        warehouse_code: this.warehouse.code,
        inv_code: this.table.inv_code, //
        inv_date: this.table.inv_date, //
        due_invoice_period: this.table.payment_term.due_invoice_period, //
        reference_purchase_code: this.po.virtual_code,
        reference_purchase_id: this.po.id,
        currency_code: this.po.currency_code,
        po_date: this.po.date,
        po_posting_date: this.po.posting_date,
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        payment_term_id: this.table.payment_term.id,
        payment_term_name: this.table.payment_term.name, //
        bank_name: this.table.bank.bank_name, //
        bank_id: this.table.bank.bank_id, //
        account_name: this.table.bank.account_name, //
        account_number: this.table.bank.account_number, //
        total_inv_value: this.table.total_inv_value.toString(), //
        item_value: this.table.item_value.toString(), //
        charge_value: this.table.charges_value.toString(), //
        tax_value: this.table.tax_value_ori.toString(), //
        data_line: this.table.data, //
        data_charge: this.table.data_charges, //
        total_discount: this.table.discount_value, //
        total_discount_po: this.po.discount, //
        total_po_value: this.po.total, //
        status_submit: this.type_submit, //
        tr_number: this.tr_number, //
        dn_date: this.dn_date,
        received_date: this.received_date,
        delivery_order_number: this.grOrderNumber,
        tr_date:
          this.tr_date == ""
            ? moment(String("0001-01-01T15:04:05Z07:00"))
            : this.tr_date, //
        due_date: moment(this.due_date).add(7, "h"),
        gr_ids: this.gr_ids,
        gr_codes: this.gr_codes,
        po_code_external: this.po_code_external,
        notes: this.new_notes,
        attachment_ids: this.attachment_ids
      };
    },
    postData() {
      this.$http
        .post("/api/v1/pi/create-invoice-manual", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("file", this.file);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.file.push({
              id: resp.data.attachment.ID,
              name: resp.data.attachment.NameFile,
              path: resp.data.attachment.PathFile,
            });
            this.attachment_ids.push(parseInt(resp.data.attachment.ID))
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWarehouse = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrder() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po", {
          params: {
            supplier_id: this.supplier.ID,
            warehouse_id: this.warehouse.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPO = resp.data.records;
            console.log(resp.data.records);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseReceive() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-gr", {
          params: {
            purchase_order_id: this.po.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionGR = resp.data.records;
            console.log(resp.data.records);
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get GR option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getListItem() {
      let gr_id = [];
      let gr_code = [];
      let gr_date = [];
      let gr_dn_date = [];
      let gr_do_number = [];
      this.gr.map(function (obj) {
        gr_id.push(obj.id);
        gr_code.push(obj.code);
        gr_date.push(obj.date);
        gr_dn_date.push(obj.dn_date);
        gr_do_number.push(obj.delivery_order_number);
      });

      console.log(this.gr)

      this.dn_date = gr_dn_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];
      this.received_date = gr_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];

      this.grOrderNumber = gr_do_number.join()
      this.gr_ids = gr_id.join()
      this.gr_codes = gr_code.join()

      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/list-gr-item", {
          purchase_receive_ids: gr_id.join(),
          purchase_order_id: this.po.id.toString(),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.item_value = (0).toFixed(2);
            this.table.charges_value = (0).toFixed(2);
            this.table.tax_value = (0).toFixed(2);
            this.table.discount_value = (0).toFixed(2);
            this.table.total_inv_value = (0).toFixed(2);

            resp.data.records.map((obj) => {
              this.table.data.push({
                item_name: obj.item_name,
                item_unit: obj.item_unit,
                qty_received: obj.qty_received,
                tax_rate: parseInt(obj.tax_rate),
                amount_uom: parseInt(obj.amount_uom),
                subtotal: "0.00",
                quantity: "0",
                discount: "0.00",
                tax: "0.00",
                net: "0.00",
                pr_id: obj.pr_id,
                discount_po: obj.discount_po,
                item_unit_id: obj.item_unit_id,
                price_po: obj.price_po,
                qty_po: obj.qty_po,
                // discount: parseFloat(obj.discount)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // tax: parseFloat(obj.tax)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // net: parseFloat(obj.net)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // id: element.id,
              });
              // this.cal()
            });

            resp.data.data_charge.map((obj) => {
              this.table.data_charges.push({
                value_po: obj.value,
                tax_po: obj.tax,
                value: "0.00",
                tax: "0.00",
                // id: element.id,
                charge_name: obj.charge_name,
                tax_rate: parseInt(obj.tax_rate),
              });
              // _this.cal();
            });
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get list item",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getInvoiceBaseline() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/invoice-baseline/" + this.supplier.ID)
        .then((resp) => {
          if (resp.code == 200) {
            this.invoice_baseline = resp.data.invoice_baseline;
            console.log(resp.data.invoice_baseline);
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get invoice baseline",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getOptionPaymentTerm();
    this.getOptionSupplier();
    this.getOptionWarehouse();
  },
  computed: {},
  watch: {
    optionPaymentTerm: function () {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period =
          result[0].due_invoice_period;
      }
    },
    "table.payment_term": function () {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period =
          result[0].due_invoice_period;

        if (this.invoice_baseline == "Invoice Date") {
          this.due_date = moment(String(this.table.inv_date))
            .add(result[0].due_invoice_period, "days")
            .format("DD/MM/YYYY");
        } else if (this.invoice_baseline == "Invoice Create Date") {
          this.due_date = moment()
            .add(result[0].due_invoice_period, "days")
            .format("DD/MM/YYYY");
        } else if (this.invoice_baseline == "Delivery Note Date") {
          this.due_date = moment(String(this.dn_date))
            .add(this.table.payment_term.due_invoice_period, "days")
            .format("DD/MM/YYYY");
        } else if (this.invoice_baseline == "GR Date") {
          this.due_date = moment(String(this.received_date))
            .add(this.table.payment_term.due_invoice_period, "days")
            .format("DD/MM/YYYY");
        }

        console.log(result[0].due_invoice_period)
        console.log(this.invoice_baseline)
        console.log(this.due_date)
        console.log(this.table.payment_term)
      }
    },
    "table.inv_date": function () {
      console.log(this.table.payment_term.due_invoice_period)
      if (
        this.table.payment_term != null &&
        this.invoice_baseline == "Invoice Date"
      ) {
        this.due_date = moment(String(this.table.inv_date))
          .add(this.table.payment_term.due_invoice_period, "days")
          .format("DD/MM/YYYY");
      } else if (this.table.payment_term != null && this.invoice_baseline == "Invoice Create Date") {
        this.due_date = moment()
          .add(this.table.payment_term.due_invoice_period, "days")
          .format("DD/MM/YYYY");
      } else if (this.table.payment_term != null && this.invoice_baseline == "Delivery Note Date") {
        this.due_date = moment(String(this.dn_date))
          .add(this.table.payment_term.due_invoice_period, "days")
          .format("DD/MM/YYYY");
      } else if (this.table.payment_term != null && this.invoice_baseline == "GR Date") {
        this.due_date = moment(String(this.received_date))
          .add(this.table.payment_term.due_invoice_period, "days")
          .format("DD/MM/YYYY");
      }
    },
    supplier: function () {
      this.po = null;
      this.warehouse = null;
      this.getOptionAccountBank(this.supplier.ID, 0);
      this.getInvoiceBaseline();
      if (this.warehouse != null) {
        this.getOptionPurchaseOrder();
      }
      this.optionPaymentTerm.map((v) => {
        if (v.id == this.supplier.PaymentTermID) {
          this.table.payment_term = {
            due_invoice_period: v.due_invoice_period,
            id: v.id,
            name: v.name,
            payment_method_id: v.payment_method_id,
            payment_method_name: v.payment_method_name,
          };
        }
      });
      console.log(this.supplier)
      console.log(this.table.payment_term)
    },
    warehouse: function () {
      if (this.supplier != null) {
        this.getOptionPurchaseOrder();
      }
    },
    po: function () {
      this.getOptionPurchaseReceive();
      this.gr = null;
      this.currency_code = this.po.currency_code
      this.po_code_external = this.po.external_code
    },
    gr: function () {
      this.table.data = []
      this.table.data_charge = []
      this.getListItem();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>